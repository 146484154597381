"use client";

import { Box, Grid, Typography, alpha } from "@mui/material";
import { DynamicTranslation } from "@serviestate/shared";
import React from "react";
import { getTranslation } from "../../utils";
import { useTranslation } from "next-i18next";

export type FancyCardItem = {
  image_id: string;
  title: string | DynamicTranslation;
  description: string | DynamicTranslation;
};

export type FancyCardsProps = {
  items: FancyCardItem[];
};

export default function FancyCards({ items }: FancyCardsProps) {
  return (
    <Grid container spacing={3} justifyContent="center" alignItems="stretch">
      {items.map((x, i) => (
        <Grid item key={`card_${i}`}>
          <FancyCard item={x} />
        </Grid>
      ))}
    </Grid>
  );
}

// const Icons: Record<string, IconType> = {
//   medal: FaMedal,
//   "flash-on": MdOutlineFlashOn,
//   "support-agent": MdOutlineSupportAgent,
// };

function FancyCard({ item }: { item: FancyCardItem }) {
  const { i18n } = useTranslation();
  const cardCoverIcon = item.image_id
    ? `/api/documents/${item.image_id}?fit=cover&format=webp&width=80&height=80`
    : null;

  return (
    <Box
      sx={{
        width: 280,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        py: 3,
        px: 3,
        height: "100%",
        transition: (theme) =>
          theme.transitions.create(["background", "color"], {
            duration: "0.5s",
          }),
        background: (theme) => theme.palette.background.paper,
        borderRadius: 2,
        "&:hover": {
          color: (theme) => theme.palette.common.white,
          backgroundColor: (theme) => theme.palette.primary.main,
          "& .icon-container": {
            backgroundColor: (theme) => theme.palette.common.white,
          },
        },
      }}
    >
      <Box
        className="icon-container"
        sx={{
          transition: (theme) =>
            theme.transitions.create(["background-color"], {
              duration: "0.5s",
            }),
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
          borderRadius: "50%",
          height: "130px",
          width: "130px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: (theme) => theme.palette.primary.main,
        }}
      >
        {cardCoverIcon && <img src={cardCoverIcon} alt="Cover" />}
      </Box>
      <Typography textAlign="center" fontWeight={700} sx={{ mt: 2 }}>
        {getTranslation(i18n.language, item.title)}
      </Typography>
      <Typography textAlign="center" sx={{ mt: 1 }}>
        {getTranslation(i18n.language, item.description)}
      </Typography>
    </Box>
  );
}
