import React from "react";
import Claim from "../claim";
import ContactUS from "../contact-us";
import FancyCards from "../fancy-cards";
import Hero from "../hero";
import PropertiesHighlights from "../properties-highlights";
import ZonesCollage from "../zones-collage";
import { Box } from "@mui/material";
import Content from "../content";
import Video from "../video";

const componentsAvailable: Record<string, React.ElementType> = {
  hero: Hero,
  claim: Claim,
  properties_highlights: PropertiesHighlights,
  zones_collage: ZonesCollage,
  fancy_cards: FancyCards,
  contact_us: ContactUS,
  content: Content,
  video: Video,
};

export type DynamicPageComponent = {
  component: string;
  props: Record<string, unknown>;
};

export type DynamicPageProps = {
  components: DynamicPageComponent[];
};

export default function DynamicPage({ components }: DynamicPageProps) {
  const componentsToRender = components.map((com) => ({
    component: componentsAvailable[com.component] ?? null,
    props: com.props,
  }));

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 6 }}>
      {componentsToRender.map(
        (x, i) =>
          x.component && (
            <Box key={`home_${i}`}>
              {React.createElement(x.component, x.props)}
            </Box>
          )
      )}
    </Box>
  );
}
