import { Box } from "@mui/material";

export default function Video({ video_id }: { video_id: string }) {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <video preload="auto" autoPlay muted loop playsInline poster="">
        <source src={`/api/documents/${video_id}`} type="video/mp4" />
      </video>
    </Box>
  );
}
